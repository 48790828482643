@tailwind base;
@tailwind components;
@tailwind utilities;
.cl-internal-b3fm6y {
    display: none !important;
}
.search-color {
    background-color: rgba(1, 252, 120, 0.14);
    border: 1px solid #01fc78;
    color: white;
}
.search-color::placeholder {
    color: white;
}
.clip-pie {
  clip-path: polygon(50% 50%, 100% 50%, 50% 0%);
}
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 12px;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e6e8ec;
  border-radius: 6px;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #272a30;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #6B7280;
}
.card-scroll::-webkit-scrollbar {
  width: 3px;
  height: 20px;
}
.card-scroll::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 5px;
}
.card-scroll::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 5px;
}
.card-scroll {
  scrollbar-width: thin;
  scrollbar-color: black #f2f2f2;
}
.scroll-container {
  padding-right: 10px; /* or margin-right: 5px; */
}
.multi-column {
  column-count: 1;
  column-gap: 2em;
}

@media (min-width: 640px) { /* Adjust this to the desired breakpoint */
  .multi-column {
    column-count: 2;
  }
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}


#__next {
  width: 100%;
}

